import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Row } from "react-flexbox-grid"

import Layout from "components/layout"
import SEO from "components/seo"
import { rhythm } from "utils/typography"

const BlogIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title={siteTitle} />
      {posts
        .filter(({ node }) => node.frontmatter.isPost !== false)
        .map(({ node }) => {
          const { frontmatter, fields } = node
          const title = frontmatter.title
          return (
            <article key={fields.slug}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={fields.slug}>
                    {title}
                  </Link>
                </h3>
                <small>{node.frontmatter.date}</small>
              </header>
              <section>
                {frontmatter.image && (
                  <Row center="xs">
                    <Link
                      style={{
                        boxShadow: `none`,
                        width: `50%`,
                        margin: `auto`,
                      }}
                      to={fields.slug}
                    >
                      <GatsbyImage
                        image={getImage(frontmatter.image)}
                        alt={title}
                        style={{ marginBottom: rhythm(0.5) }}
                        imgStyle={{
                          display: `block`,
                          height: `100%`,
                          width: `auto`,
                          margin: `auto`,
                        }}
                      />
                    </Link>
                  </Row>
                )}
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </article>
          )
        })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            isPost
            image: featured {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, height: 1200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    }
  }
`
